<div
  class="flex flex-row items-center justify-between rounded-lg border border-red-600 bg-red text-red-800 py-1.5 px-3 text-xsm"
>
  <div class="flex flex-row items-center">
    <img ngSrc="/assets/images/icons/error.svg" alt="" class="mt-0.5 mr-2" height="16" width="16" />
    {{ text }}
  </div>
  <button type="button" (click)="close()" class="p-1 ms-4">
    <img ngSrc="/assets/images/icons/close.svg" alt="" width="16" height="16" />
  </button>
</div>
