import { Injectable } from '@angular/core'
import { User } from '../interfaces'
// import { AuthSocialService } from './auth-social.service'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly userStorage = 'user'
  user: User | null = null
  authenticate$ = new Subject<User>()

  get enabled(): boolean {
    return Boolean(this.user?.enabled || this.user?.enabled_trial)
  }

  get nameFirstLetters(): string {
    return this.user ? `${this.user?.first_name.substr(0, 1)}${this.user?.last_name.substr(0, 1)}` : ''
  }

  constructor() {
    const storedUser = localStorage.getItem(this.userStorage)
    if (!this.user && storedUser) {
      this.user = JSON.parse(storedUser)
    }
  }

  authenticate(user: User) {
    this.user = user
    localStorage.setItem(this.userStorage, JSON.stringify(user))
    this.authenticate$.next(user)
  }

  logout() {
    // AuthSocialService.resetKeys()
    this.user = null
    localStorage.removeItem(this.userStorage)
  }

  isAuthenticated() {
    return this.user
  }
}
