<form [formGroup]="form" (ngSubmit)="submit()" class="bg-slate">
  <div class="border-b border-slate-50 px-4 py-2 bg-white">
    <h1 class="text-xl text-slate-900 font-semibold">User persona</h1>
    <p class="text-xs text-slate-600">
      Create a starting persona, to better comprehend your users.
      <a href="https://www.youtube.com/watch?v=rujy8TKrfYU" target="_blank" class="text-purple-500">Watch tutorial</a>
    </p>
  </div>
  <div class="p-4 m-4 bg-white rounded-lg drop-shadow border-slate-50">
    <label class="required">Persona demographics</label>
    <div class="text-xsm text-slate-500 mb-2">
      Characteristics, such as specific occupation, age, gender, and location.
    </div>
    <textarea
      formControlName="persona"
      class="form-control"
      [maxlength]="PERSONA_MAX_LENGTH"
      [placeholder]="'e.g.' + examples[0].persona"
      [class.error]="formsService.isFieldInvalid('persona')"
    ></textarea>
    <div class="flex flex-row justify-between items-center mt-1">
      <app-try-example [examples]="examples" [form]="form"></app-try-example>
      <app-chars-used [usedChars]="personaLength" [totalChars]="PERSONA_MAX_LENGTH" class="text-right"></app-chars-used>
    </div>

    <label class="mt-4">Scenario</label>
    <div class="text-xsm text-slate-500 mb-2">Why and how the persona interacts with a product or service.</div>
    <textarea
      formControlName="context"
      class="form-control"
      [placeholder]="'e.g.' + examples[0].context"
      [class.error]="formsService.isFieldInvalid('context')"
      [maxlength]="CONTEXT_MAX_LENGTH"
    ></textarea>
    <div class="flex flex-row justify-end">
      <app-chars-used [usedChars]="contextLength" [totalChars]="CONTEXT_MAX_LENGTH" class="text-right"></app-chars-used>
    </div>

    <label class="mt-8">Attributes</label>
    <p class="mb-2 text-slate-500">Select the characteristics to help paint a detailed picture of the persona.</p>
    <div
      class="selected-attributes bg-slate px-3 pt-2 pb-1 rounded-lg border flex flex-row flex-wrap mb-2 min-h-12"
      [class.border-slate-100]="selectedAttributes.size"
      [class.border-red-600]="!selectedAttributes.size"
    >
      @for (attribute of selectedAttributes; track attribute.name) {
        <button
          class="flex flex-row items-center text-sm px-3 py-2 bg-slate-50 hover:bg-slate-100 transition-all rounded text-slate-500 mr-2 mb-1"
          type="button"
          (click)="deleteAttribute(attribute)"
        >
          {{ attribute.value }}
          <img ngSrc="assets/images/icons/close.svg" alt="Remove" height="16" width="16" class="ms-2" />
        </button>
      }
    </div>
    @if (!selectedAttributes.size) {
      <div class="text-xsm text-red-600 mb-2">Please add at least one attribute.</div>
    }
    @if (availableAttributes.size) {
      <div class="available-attributes flex flex-row flex-wrap">
        @for (attribute of availableAttributes; track attribute.name) {
          <button
            class="flex flex-row items-center text-sm px-3 py-2 bg-white border border-slate-100 hover:bg-slate-hover hover:border-slate-200 transition-all rounded-lg text-slate-500 mr-2 mb-1"
            type="button"
            (click)="addAttribute(attribute)"
          >
            {{ attribute.value }}
            <img ngSrc="assets/images/icons/plus.svg" alt="Add" height="12" width="12" class="ms-2" />
          </button>
        }
      </div>
    }

    <div class="board-types">
      <div
        class="board-types__type"
        (click)="boardTypeSelected(PersonaBoardTypes.board)"
        [class.active]="form.get('boardType')?.value === PersonaBoardTypes.board"
      >
        @if (form.get('boardType')?.value === PersonaBoardTypes.board) {
          <img
            ngSrc="/assets/images/icons/persona-board-checked.svg"
            alt=""
            height="20"
            width="20"
            class="absolute right-[12px] top-[12px]"
          />
        }
        <img ngSrc="/assets/images/icons/persona-board.svg" alt="" height="20" width="20" />
        <strong class="board-types__title">Persona board</strong>
        <div class="text-slate-500">Sticky notes: Ideal for workshops</div>
      </div>
      <div
        class="board-types__type"
        (click)="boardTypeSelected(PersonaBoardTypes.card)"
        [class.active]="form.get('boardType')?.value === PersonaBoardTypes.card"
      >
        @if (form.get('boardType')?.value === PersonaBoardTypes.card) {
          <img
            ngSrc="/assets/images/icons/persona-board-checked.svg"
            alt=""
            height="20"
            width="20"
            class="absolute right-[12px] top-[12px]"
          />
        }
        <img ngSrc="/assets/images/icons/persona-card.svg" alt="" height="20" width="20" />
        <strong class="board-types__title">Persona card</strong>
        <div class="text-slate-500">Slide page: Ideal for reports</div>
      </div>
    </div>

    <label>Output language</label>
    <select class="form-control mb-2" (change)="selectLanguage($event)">
      @for (language of languages; track language.id) {
        <option [value]="language.id" [selected]="language['defaultValue']">{{ language.name }}</option>
      }
    </select>

    @if (api.error$ | async; as error) {
      <app-error-message [text]="error" (closeEvent)="api.error$.next('')" class="my-2"></app-error-message>
    }

    <button
      type="submit"
      class="btn btn_primary w-full flex flex-row items-center justify-center"
      [disabled]="form.invalid || sending"
    >
      @if (sending) {
        <app-loading
          [showText]="false"
          [showSmall]="true"
          [showInline]="true"
          class="me-2 relative top-0.5"
        ></app-loading>
      }
      Generate
    </button>
  </div>
</form>
