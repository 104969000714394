import { Component } from '@angular/core'
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { AsyncPipe, NgOptimizedImage } from '@angular/common'
import { Persona, PersonaAttribute, PersonaBoardTypes, PersonaRequestDto } from '@shared/interfaces'
import { CharsUsedComponent } from '@shared/components/chars-used/chars-used.component'
import { TryExampleComponent } from '@shared/components/try-example/try-example.component'
import { FormsService } from '@shared/services/forms.service'
import { AuthService } from '@shared/services/auth.service'
import { ApiService } from '@shared/services/api.service'
import { ErrorMessageComponent } from '@shared/components/error-message/error-message.component'
import { finalize } from 'rxjs'
import { LoadingComponent } from '@shared/components/loading/loading.component'

@Component({
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    CharsUsedComponent,
    TryExampleComponent,
    ErrorMessageComponent,
    AsyncPipe,
    LoadingComponent,
  ],
  selector: 'app-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.scss'],
})
export class PersonaComponent {
  public readonly languages = [
    {
      id: 'Arabic',
      name: 'Arabic',
    },
    {
      id: 'Bulgarian',
      name: 'Bulgarian',
    },
    {
      id: 'Cantonese',
      name: 'Cantonese',
    },
    {
      id: 'Catalan',
      name: 'Catalan',
    },
    {
      id: 'Croatian',
      name: 'Croatian',
    },
    {
      id: 'Czech',
      name: 'Czech',
    },
    {
      id: 'Danish',
      name: 'Danish',
    },
    {
      id: 'Dutch',
      name: 'Dutch',
    },
    {
      defaultValue: true,
      id: 'English',
      name: 'English',
    },
    {
      id: 'Finnish',
      name: 'Finnish',
    },
    {
      id: 'French',
      name: 'French',
    },
    {
      id: 'German',
      name: 'German',
    },
    {
      id: 'Greek',
      name: 'Greek',
    },
    {
      id: 'Hebrew',
      name: 'Hebrew',
    },
    {
      id: 'Hindi',
      name: 'Hindi',
    },
    {
      id: 'Hungarian',
      name: 'Hungarian',
    },
    {
      id: 'Italian',
      name: 'Italian',
    },
    {
      id: 'Latvian',
      name: 'Latvian',
    },
    {
      id: 'Lithuanian',
      name: 'Lithuanian',
    },
    {
      id: 'Malay',
      name: 'Malay',
    },
    {
      id: 'Mandarin',
      name: 'Mandarin',
    },
    {
      id: 'Norwegian',
      name: 'Norwegian',
    },
    {
      id: 'Polish',
      name: 'Polish',
    },
    {
      id: 'Portuguese',
      name: 'Portuguese',
    },
    {
      id: 'Romanian',
      name: 'Romanian',
    },
    {
      id: 'Russian',
      name: 'Russian',
    },
    {
      id: 'Slovak',
      name: 'Slovak',
    },
    {
      id: 'Slovenian',
      name: 'Slovenian',
    },
    {
      id: 'Spanish',
      name: 'Spanish',
    },
    {
      id: 'Swedish',
      name: 'Swedish',
    },
    {
      id: 'Turkish',
      name: 'Turkish',
    },
    {
      id: 'UK English',
      name: 'UK English',
    },
  ]
  public readonly CONTEXT_MAX_LENGTH = 500
  public readonly PERSONA_MAX_LENGTH = 500
  public readonly PersonaBoardTypes = PersonaBoardTypes
  public readonly examples = [
    {
      persona:
        '25-34, Female, Software developer from Los Angeles, California, $60,000-$80,000 per year, single, and No children.',
      context:
        'Looking for a user-friendly platform to collaborate and share code with her team across different locations.',
    },
    {
      persona:
        '40-year-old, Male, Content Writer from Nigeria, $50,000-$65,000 per year, married, has 3 children, working remotely from various locations.',
      context:
        'Seeking a flexible and robust AI tool to assist in content generation and editing for their diverse set of clients.',
    },
    {
      persona:
        '45-year-old, Female, Eco-tour Guide from New Delhi, $30,000-$35,000 per year, single mother, 3 children.',
      context:
        'Desires an intuitive app for promoting eco-tourism and managing client bookings while on the go in various remote locations.',
    },
  ]

  availableAttributes: Set<PersonaAttribute> = new Set([
    { name: 'interests_and_traits', value: 'Interests & traits' },
    { name: 'goals_and_needs', value: 'Goals & needs' },
  ])
  selectedAttributes: Set<PersonaAttribute> = new Set([
    { name: 'tasks_and_activities', value: 'Tasks & activities' },
    { name: 'challenges_and_pain_points', value: 'Challenges & pain points' },
    { name: 'touch_points_and_channels', value: 'Touch-points & channels' },
    { name: 'attitudes_and_thoughts', value: 'Attitudes & thoughts' },
    { name: 'opportunities_and_features', value: 'Opportunities & features' },
  ])

  form = this.fb.group({
    persona: [
      '',
      {
        nonNullable: true,
        validators: [Validators.required],
      },
    ],
    context: ['', { nonNullable: true, validators: [Validators.maxLength(this.CONTEXT_MAX_LENGTH)] }],
    language: ['English'],
    boardType: [PersonaBoardTypes.board],
  })

  sending = false

  get contextLength(): number {
    return this.form.get('context')?.value?.length ?? 0
  }

  get personaLength(): number {
    return this.form.get('persona')?.value?.length ?? 0
  }

  constructor(
    private fb: FormBuilder,
    public formsService: FormsService,
    protected api: ApiService,
    protected auth: AuthService,
  ) {
    this.formsService.setForm(this.form)
  }

  addAttribute(attribute: PersonaAttribute) {
    if (this.availableAttributes.has(attribute)) {
      this.availableAttributes.delete(attribute)
      this.selectedAttributes.add(attribute)
    }
  }

  deleteAttribute(attribute: PersonaAttribute) {
    if (this.selectedAttributes.has(attribute)) {
      this.selectedAttributes.delete(attribute)
      this.availableAttributes.add(attribute)
    }
  }

  boardTypeSelected(boardType: PersonaBoardTypes) {
    this.form.get('boardType')?.patchValue(boardType)
  }

  async drawLayout(persona: Persona) {
    // @ts-ignore
    const miro = window['miro']

    let categories = []
    if (persona.interests_and_traits) {
      categories.push({
        category: 'INTERESTS AND TRAITS',
        items: [...persona.interests_and_traits],
        background: 'gray',
      })
    }
    if (persona.goals_and_needs) {
      categories.push({
        category: 'GOALS AND NEEDS',
        items: [...persona.goals_and_needs],
        background: 'light_yellow',
      })
    }
    if (persona.tasks_and_activities) {
      categories.push({
        category: 'TASKS AND ACTIVITIES',
        items: [...persona.tasks_and_activities],
        background: 'green',
      })
    }
    if (persona.challenges_and_pain_points) {
      categories.push({
        category: 'CHALLENGES AND PAIN POINTS',
        items: [...persona.challenges_and_pain_points],
        background: 'orange',
      })
    }
    if (persona.touch_points_and_channels) {
      categories.push({
        category: 'TOUCH POINTS AND CHANNELS',
        items: [...persona.touch_points_and_channels],
        background: 'violet',
      })
    }
    if (persona.attitudes_and_thoughts) {
      categories.push({
        category: 'ATTRIBUTES AND THOUGHTS',
        items: [...persona.attitudes_and_thoughts],
        background: 'pink',
      })
    }
    if (persona.opportunities_and_features) {
      categories.push({
        category: 'OPPORTUNITIES AND FEATURES',
        items: [...persona.opportunities_and_features],
        background: 'light_blue',
      })
    }

    await miro.board.createImage({
      url: `data:image/png;base64,${persona.avatar}`,
      x: 0,
      y: -400,
      width: 200,
      rotation: 0,
    })

    await miro.board.createText({
      content: `Name: ${persona.fictional_name}<br>Age: ${persona.age}<br>Occupation: ${persona.experience_level} ${persona.occupation}<br>Quote: ${persona.attitude_quote}`,
      style: {
        fontSize: 36,
      },
      width: 1500,
      x: 1000,
      y: -400,
    })

    let x = 0
    let y = 0
    const width = 200
    const height = 200
    for (const category of categories) {
      await miro.board.createText({
        content: category.category,
        style: {
          fontSize: 36,
          textAlign: 'left',
        },
        width: 2000,
        x: 900,
        y: y - height,
      })

      for (const item of category.items) {
        await miro.board.createStickyNote({
          content: item,
          style: {
            fillColor: category.background,
            fontSize: 14,
          },
          x,
          y,
          shape: 'square',
          width,
        })
        x += width
      }
      x = 0
      y += 2 * height
    }
  }

  submit() {
    if (this.sending) {
      return
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched()
      return
    }

    if (!this.selectedAttributes.size) {
      return
    }

    this.sending = true
    this.api
      .getPersona({
        ...this.form.value,
        attributes: [...this.selectedAttributes].map(attribute => attribute.name),
      } as PersonaRequestDto)
      .pipe(finalize(() => (this.sending = false)))
      .subscribe(async ({ persona }) => {
        await this.drawLayout(persona)
      })
  }

  selectLanguage(event: Event) {
    this.form.get('language')?.patchValue((event.target as HTMLSelectElement).value)
  }
}
