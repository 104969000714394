import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  async ngOnInit() {
    // @ts-ignore
    const miro = window['miro']

    await miro.board.ui.on('icon:click', async () => {
      await miro.board.ui.openPanel({ url: '', height: 720 })
    })
  }
}
