import { Component, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { NgOptimizedImage } from '@angular/common'

@Component({
  standalone: true,
  selector: 'app-try-example',
  templateUrl: './try-example.component.html',
  styleUrls: ['./try-example.component.scss'],
  imports: [NgOptimizedImage],
})
export class TryExampleComponent {
  @Input() form: FormGroup | undefined
  @Input() examples: any[] = []
  currentValue = 0

  tryExample() {
    if (this.currentValue >= this.examples.length) {
      this.currentValue = 0
    }
    const value = this.examples[this.currentValue]
    if (value) {
      this.form?.patchValue(value)
      this.currentValue++
    }
  }
}
