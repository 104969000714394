export enum PersonaBoardTypes {
  board = 'board',
  card = 'card',
}

export interface PersonaAttribute {
  name: string
  value: string
}

export interface Order {
  id: number
  created: Date
  updated: Date
  expiry: Date
  order_id: number
  product_id: number
  product_name: string
  product_price: number
  product_term: number
  data: string
}

export interface User {
  created: Date
  updated: Date
  enabled: boolean
  enabled_trial: boolean
  disable_access_date: Date
  email: string
  first_name: string
  id: number
  last_name: string
  orders?: Order[]
  token: string
  provider: string
  figma_user_id: string
  tool_chat: number
  tool_clustering: number
  tool_design_checklist: number
  tool_document_chat: number
  tool_information_architecture: number
  tool_persona: number
  tool_product_brief: number
  tool_user_journey_mapping: number
  tool_user_interview: number
  tool_ux_writing: number
  trial_ends: Date
  trial_ends_days: number
}

export interface Persona {
  age: string
  avatar?: string
  attitude_quote: string
  experience_level: string
  fictional_name: string
  occupation: string
  interests_and_traits?: string[]
  goals_and_needs?: string[]
  tasks_and_activities?: string[]
  challenges_and_pain_points?: string[]
  touch_points_and_channels?: string
  attitudes_and_thoughts?: string[]
  opportunities_and_features?: string[]
}

export interface PersonaRequestDto {
  attributes: Array<
    | 'interests_and_traits'
    | 'goals_and_needs'
    | 'tasks_and_activities'
    | 'challenges_and_pain_points'
    | 'touch_points_and_channels'
    | 'attitudes_and_thoughts'
    | 'opportunities_and_features'
  >
  context: string
  language?: string
  persona: string
}

export interface PersonaResponseDto {
  id: number
  persona: Persona
}
