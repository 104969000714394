import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ApiService } from '../../services/api.service'
import { NgOptimizedImage } from '@angular/common'

@Component({
  standalone: true,
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  imports: [NgOptimizedImage],
})
export class ErrorMessageComponent {
  @Input() text = ''
  @Output() closeEvent = new EventEmitter<boolean>()

  constructor(private api: ApiService) {}

  close() {
    this.api.error$.next('')
    this.closeEvent.emit(true)
  }
}
