import { Environment } from './interface'

export const environment: Environment = {
  apiHost: 'https://api-dev.qoqo.ai',
  email: 'hello@qoqo.ai',
  environment: 'development',
  googleSocialProviderClientId: '910610486322-jjtt24vv4rr6iorqrf39slvnrngjmep6.apps.googleusercontent.com',
  production: false,
  website: 'https://qoqo.ai',
}
