import { Component, Input } from '@angular/core'

@Component({
  standalone: true,
  selector: 'app-chars-used',
  templateUrl: './chars-used.component.html',
  styleUrls: ['./chars-used.component.scss'],
})
export class CharsUsedComponent {
  @Input() totalChars = 0
  @Input() usedChars = 0

  constructor() {}
}
