import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  form: UntypedFormGroup | undefined

  isFieldInvalid(field: string): boolean {
    // @ts-ignore
    return this.form.get(field).touched && this.form.get(field).invalid
  }

  setForm(f: UntypedFormGroup) {
    this.form = f
  }
}
