<span class="loader" [class.loader_block]="!showInline" [class.loader_small]="showSmall">
  <span class="loader__wrapper">
    @if (textBefore) {
      @if (showText) {
        <span class="loader__text me-2">{{ message }}</span>
      }
      <span class="loader__figure"></span>
    }
    @if (!textBefore) {
      <span class="loader__figure"></span>
      @if (showText) {
        <span class="loader__text">{{ message }}</span>
      }
    }
  </span>
</span>
