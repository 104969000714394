import { Component, Input } from '@angular/core'

@Component({
  standalone: true,
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() message = 'Loading...'
  @Input() showInline = false
  @Input() showSmall = false
  @Input() showText = true
  @Input() textBefore = false
}
