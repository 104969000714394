import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http'
import { Observable, Subject, throwError } from 'rxjs'
import { environment } from '../../../environments/environment'
import { catchError } from 'rxjs/operators'
import { PersonaRequestDto, PersonaResponseDto } from '../interfaces'

@Injectable()
export class ApiService {
  error$: Subject<string> = new Subject<string>()
  success$: Subject<string> = new Subject<string>()

  constructor(private http: HttpClient) {}

  fetchErrorFromResponse(errorResponse: HttpErrorResponse): string {
    switch (errorResponse.status) {
      case 0:
        return 'Ops! Something went wrong. Please check your connection, or try again later'
      case HttpStatusCode.InternalServerError:
        return 'Service is temporary unavailable. Please try again in a moment'
      case HttpStatusCode.TooManyRequests:
        return 'QoQo is currently overloaded. Please try again later'
      default:
        const msg = errorResponse.error?.message
        if (msg) {
          return Array.isArray(msg) ? msg.join('. ') : msg
        } else {
          return errorResponse.statusText
        }
    }
  }

  handleError(errorResponse: HttpErrorResponse) {
    const error = this.fetchErrorFromResponse(errorResponse)
    this.error$.next(error)
    return throwError(errorResponse)
  }

  handleErrorMessage(error: string) {
    this.error$.next(error)
  }

  handleSuccess(success: any) {
    this.success$.next(success)
  }

  getPersona(data: PersonaRequestDto): Observable<PersonaResponseDto> {
    return this.http
      .post<PersonaResponseDto>(`${environment.apiHost}/intelligence/v2/persona`, data, {
        headers: {
          token: 'de5f196d-0f23-401e-8d48-7ae002a61c58',
        },
      })
      .pipe(catchError(err => this.handleError(err)))
  }
}
